import React from 'react';
import * as style from './HeadingText.module.scss';

const HeadingText = ({heading, text}) => {
	 return(
		 <div className={style.wrapper}>
		 	<div className={style.content}>
				<h2 className={style.heading}>{heading}</h2>
				<p className={style.text}>{text}</p>
			</div>
		 </div>
	 )
}
export default HeadingText;
