import React from 'react';
import * as style from './Portrait.module.scss';
import { GatsbyImage } from 'gatsby-plugin-image';

const Portrait = ({image, quote, name, title}) => {
	 return(
		 <div className={style.wrapper}>
			 <div className={style.content}>
			 	<GatsbyImage image={image} alt='portrait' className={style.portrait}/>
				<div className={style.quote}>
					<div className={style.text}>
						{quote}
					</div>
					<div className={style.bio}>
						<div className={style.name}>{name}</div>
						<div>{title}</div>
					</div>
				</div>
			 </div>
		 </div>
	 )
}
export default Portrait;
