import React from 'react';
import * as style from './TestimonialCard.module.scss';
import logo_placeholder from './logo.png';
import portrait_placeholder from './portrait.png';
import { GatsbyImage } from 'gatsby-plugin-image';

const TestimonialCard = ({name, title, text, logo, portrait}) => {
	 return(
		 <div className={style.card}>
		 	{
				logo ?
				<GatsbyImage image={logo.gatsbyImage} className={style.company} alt=""/> :
				<img src={logo_placeholder} className={style.company} />
			}

			 <p className={style.text}>
				 {text}
			 </p>
			 <div className={style.bio}>
			 	{
					portrait ?
					<GatsbyImage image={portrait.gatsbyImage} className={style.portrait} alt=""/>  :
					<img src={portrait_placeholder} className={style.portrait} />

				}
				 <div className={style.info}>
					 <div className={style.name}>{name}</div>
					 <div>{title}</div>
				 </div>
			 </div>
		 </div>
	 )
}
export default TestimonialCard;
