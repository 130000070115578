import React from 'react';
import Layout from '../components/Layout/Layout';
import Portrait from '../components/Portrait/Portrait';
import HeadingText from '../components/HeadingText/HeadingText';
import Testimonials from '../components/Testimonials/Testimonials';
import {Link, graphql, StaticQuery} from 'gatsby';

const OmPage = () => {
  return(
    <StaticQuery query={graphql`{
        allWpContent(filter: { title: {eq: "Om Os"}}){
          edges{
            node{
              omPage{
                omOs
                quote
                name
                title
                portrait{
                  gatsbyImage(width: 600, placeholder: BLURRED)
                }
              }
            }
          }
        }
    }`} render={props => (
        props.allWpContent.edges.map(data => (
          <Layout>
            <Portrait
              image={data.node.omPage.portrait.gatsbyImage}
              name={data.node.omPage.name}
              title={data.node.omPage.title}
              quote={data.node.omPage.quote}
            />
            <HeadingText
              heading='Om Paulsen Regnskab'
              text={data.node.omPage.omOs}
              />
            <Testimonials />
          </Layout>
        ))


    )} />

  )
}

export default OmPage;
