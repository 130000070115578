import React from 'react';
import * as style from './Testimonials.module.scss';
import TestimonialCard from '../TestimonialCard/TestimonialCard';
import {StaticQuery, graphql} from 'gatsby';

const Testimonials = () => {
	 return(
		 <div className={style.wrapper}>
		 	<StaticQuery query={graphql`
				{
					allWpUdtalelse {
						edges {
							node {
									id
								udtalelser{
									name
									title
									quote
									portrait{
										sourceUrl
										gatsbyImage(width: 100, placeholder: BLURRED)
									}
									logo{
										sourceUrl
										gatsbyImage(width: 100, placeholder: BLURRED)
									}
								}
							}
						}
					}
				}
			`} render={props => (
				<div className={style.content}>
				{
					props.allWpUdtalelse.edges.map(udtalelse => (
						<TestimonialCard
							name={udtalelse.node.udtalelser.name}
							title={udtalelse.node.udtalelser.title}
							text={udtalelse.node.udtalelser.quote}
							portrait={udtalelse.node.udtalelser.portrait}
							logo={udtalelse.node.udtalelser.logo}
						/>
					))
				}
				</div>
			)} />

		 </div>
	 )
}
export default Testimonials;
